import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot from react-dom/client
import App from "./App";

// Find the root DOM node where the React app will be mounted
const rootElement = document.getElementById("root");

// Ensure that the root element exists
if (rootElement) {
  // Create a root using React 18's createRoot API
  const appRoot = createRoot(rootElement); // Use createRoot from react-dom/client

  // Render the App component within the root in StrictMode for highlighting potential problems
  appRoot.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
} else {
  console.error("Failed to find the root element to mount the React app.");
}
