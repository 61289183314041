import { createSlice } from "@reduxjs/toolkit";

export const playlistSlice = createSlice({
  name: "playlist",
  initialState: {
    playlistId: null,
    needsRefresh: false,
  },
  reducers: {
    setPlaylistId: (state, action) => {
      state.playlistId = action.payload;
    },
    setNeedsRefresh: (state, action) => {
      state.needsRefresh = action.payload;
    },
  },
});

export const { setPlaylistId, setNeedsRefresh } = playlistSlice.actions;

export default playlistSlice.reducer;
