import React, { lazy, Suspense, useCallback } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { store } from "./store";
import PrivateRoute from "./PrivateRoute";

// Lazy loading route components
const Login = lazy(() => import("./Login"));
const Lists = lazy(() => import("./Lists"));
const Watchlist = lazy(() => import("./Watchlist"));
const Favorites = lazy(() => import("./Favorites"));
const Ratings = lazy(() => import("./Ratings"));
const Recommendations = lazy(() => import("./Recommendations"));
const Playlist = lazy(() => import("./Playlist"));
const PublicPlaylists = lazy(() => import("./PublicLists"));
const SettingsPage = lazy(() => import("./Settings"));
const TraktAuth = lazy(() => import("./TraktAuth"));
const TmdbAuth = lazy(() => import("./TmdbAuth"));
const TmdbAuthV4 = lazy(() => import("./TmdbAuthV4"));

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Suspense
          fallback={
            <div style={{ height: "100vh", backgroundColor: "#252525" }}></div>
          }
        >
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/lists"
              element={
                <PrivateRoute>
                  <Lists />
                </PrivateRoute>
              }
            />
            <Route
              path="/watchlist"
              element={
                <PrivateRoute>
                  <Watchlist />
                </PrivateRoute>
              }
            />
            <Route
              path="/favorites"
              element={
                <PrivateRoute>
                  <Favorites />
                </PrivateRoute>
              }
            />
            <Route
              path="/ratings"
              element={
                <PrivateRoute>
                  <Ratings />
                </PrivateRoute>
              }
            />
            <Route
              path="/recommendations"
              element={
                <PrivateRoute>
                  <Recommendations />
                </PrivateRoute>
              }
            />
            <Route
              path="/playlist/:user/:id"
              element={
                <PrivateRoute>
                  <Playlist />
                </PrivateRoute>
              }
            />
            <Route
              path="/discover"
              element={
                <PrivateRoute>
                  <PublicPlaylists />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <SettingsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/trakt-auth"
              element={
                <PrivateRoute>
                  <TraktAuth />
                </PrivateRoute>
              }
            />
            <Route
              path="/tmdb-auth"
              element={
                <PrivateRoute>
                  <TmdbAuth />
                </PrivateRoute>
              }
            />
            <Route
              path="/tmdb-auth-v4"
              element={
                <PrivateRoute>
                  <TmdbAuthV4 />
                </PrivateRoute>
              }
            />
          </Routes>
        </Suspense>
      </Router>
    </Provider>
  );
};

export default App;
