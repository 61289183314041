import { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const sessionCookie = Cookies.get("session_status");
    if (!sessionCookie || sessionCookie !== "logged_in") {
      navigate("/"); // Redirect to home if cookie is not present
    }
  }, [navigate]);

  return children;
};

export default PrivateRoute;
