// store.js
import { configureStore } from "@reduxjs/toolkit";
import playlistReducer from "./playlistSlice";
import csrfReducer from "./csrfSlice"; // Import the csrfReducer

export const store = configureStore({
  reducer: {
    playlist: playlistReducer,
    csrf: csrfReducer, // Add the csrfReducer to your store
  },
});
